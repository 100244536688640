* {
  box-sizing: border-box;
 }
 *:before,
 *:after {
  box-sizing: border-box;
 }
 
 body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #dbe2ef; */
  background-color: #e7dfd5;
  padding-top: 0;
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  margin-bottom: 30px;
}

.gh-button {
  margin: 5px 5px 10px;
  width: 150px;
}

.picture {
  width:100%;
  height:auto;
}

.profile-pic-div {
  width: 175px;
  height: 175px;
  margin-left: auto;
  margin-right: auto;
}

.description {
  width: 50%;
  min-width: 300px;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.projects {
  width: 100%;
  color: #e7dfd5;
  min-width: 300px;
  text-align: center;
  background-color: #204051;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.project-group {
  margin: 0px;
}

.single-project {
  color: black;
  width: 60%;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto; 
  margin-top: 30px;
  margin-bottom: 30px;
}

.sample-image {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border: solid;
  border-width: 2px;
  border-color: #204051;
  border-radius: .25rem;
}

.project-description {
  color: black;
  width: 95%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 10px;
  
}


.jumbotron {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.contact-header {
  margin-bottom: 25px;
}
.contact-form {
  text-align: left;
  padding: 10px;
  background-color: #f2f3f4;
}

.footer {
  position: absolute;
  bottom:0;
  width:100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 10px;
  text-align: center;
  background-color: #343a40;
}

.copyright {
  color: white;
  margin-top:5px;
}

.content {
  min-height: 100vh; 
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 150px; 
 }

 .bullet-item {
   margin-bottom: 15px;
 }

 .site-link {
   margin-top: 20px;
   margin-bottom: 20px;
   margin-right: 15px;
   margin-left: 15px;
   width: 140px;
 }

 .site-link-div {
   margin-left: auto;
   margin-right: auto;
 }

 hr {
   height: 5px;
   border-width: 2px;
   border-color: #ccc;
 }
 